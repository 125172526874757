import React, { useState } from 'react';
import { useLocation } from "@reach/router"
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import scrollTo from 'gatsby-plugin-smoothscroll';
import LogoSvg from 'images/logo-white.svg';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

// const Header = ({ title }) => (
//   <AnimatedContainer>
//     <Container>
//       <Link to="/">
//         <Title as="h1">{title}</Title>
//       </Link>

//       <Nav />
//     </Container>
//   </AnimatedContainer>
// );
const Header = ({ title }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const handleClicked = () => {
    setMenuOpen(opened => !opened);
  };

  return (
    <header>
      <nav className="navbar navbar-absolute">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-2">
              <Link className="navbar-brand" to="/">
                <LogoSvg />
              </Link>
            </div>
            <div className="" style={{ flexGrow: 2 }}>
              <ul
                className={`nav ${
                  menuOpen ? 'nav-show' : ''
                } align-items-center`}
                style={{ justifyContent: 'center' }}
              >
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === '/services' ? 'active' : ''
                    }`}
                    to="/services"
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === '/processes' ? 'active' : ''
                    }`}
                    to="/processes"
                  >
                    Processes
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === '/values' ? 'active' : ''
                    }`}
                    to="/values"
                  >
                    Team Values
                  </Link>
                </li>
                <li className="li-buy-button nav-item">
                  <Link className="nav-link" to="#contactmessage">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-2 text-right">
              <button
                className="buy-button btn btn-outline-light"
                onClick={() => scrollTo('#contactmessage')}
              >
                Contact Us
              </button>
              <button
                className={`btn nav-toggle-btn ${
                  menuOpen ? 'nav-toggle-close' : ''
                }`}
                onClick={handleClicked}
              >
                <span className="lines"></span>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
