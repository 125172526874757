import React from 'react';

const SectionTitle = ({ children }) => (
  <div className="row justify-content-center">
    <div className="col-12 text-center">
      <div className="section-title mb-4 pb-0">
        <h2 className="title mb-4">{children}</h2>
      </div>
    </div>
  </div>
);

export default SectionTitle;