import React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import * as Requests from "utils/requests";

const onSubmit = async values => {
  const { message, email, name } = values;
  const data = {
    text: `*Form posted*: \n ${name}, <${email}>\n ${message}`,
  };
  try {
    await Requests.postJson(
      'https://hooks.slack.com/services/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',
      data
    ).then(Requests.getResponseJsonOrError);
  } catch (error) {
    console.log(error);
    return {
      [FORM_ERROR]:
        'Something when wrong during sending. Please try again later, or use our email.',
    };
  }
}

const ContactForm = () => (
  <Form
    onSubmit={onSubmit}
    validate={values => {
      const errors = {};
      if (!values.email) {
        errors.email = 'This field is required.';
      }
      return errors;
    }}
    initialValues={{}}
  >
    {({ submitError, submitSucceeded, handleSubmit, form, submitting, pristine, values }) => (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group position-relative">
              <Field
                id="contactmessage"
                name="message"
                component="textarea"
                type="text"
                placeholder="Your message..."
                className="form-control"
                rows="5"
                required=""
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group position-relative">
              <Field
                name="email"
                component="input"
                type="email"
                placeholder="Your Email *"
                className="form-control"
                required=""
              >
                {({ input, meta }) => (
                  <div>
                    <input
                      {...input}
                      id="email"
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control"
                    />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group position-relative">
              <div>
                <Field
                  name="name"
                  type="text"
                  component="input"
                  placeholder="Name"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          {submitError && <div className="col-md-12 error_message">{submitError}</div>}
          {submitSucceeded && <div className="col-md-12 success_message"><h5>Submitted!</h5></div>}
          <div className="col-md-12">
            <div className="send">
              <button
                type="submit"
                disabled={submitting || pristine}
                className="btn btn-light w-100"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    )}
  </Form>
);

export default ContactForm;