import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ContactForm from "./ContactForm";

const Footer = ({ title, contacts }) => (
  <React.Fragment>
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{"Let's work together"}</h1>
          </div>
          <div className="col-12">
            <h4>
              Hey, we are a small team from Ukraine with a passion for creating
              software products. 
              <br />
              We are always considering new projects. Tell us about the stuff you would like to build.
            </h4>
          </div>
        </div>
        <div className="row align-items-center my-5">
          <div className="col-12 col-lg-7">
            <ContactForm />
          </div>
          <div className="col-12 col-lg-4 offset-lg-1">
            <div className="row contact-details">
              <div className="col-12 mb-0 pb-0 pb-md-2">
                <div className="contact-detail">
                  <div className="icon float-left">
                    <i className="mdi mdi-email-variant mdi-36px mr-3"></i>
                  </div>
                  <div className="mt-3 overflow-hidden d-block">
                    <h4 className="font-weight-bold mb-0">Email</h4>
                    <a className="h5" href={`mailto:${contacts.email}`}>
                      {contacts.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-0 pb-0 pb-md-2">
                <div className="contact-detail">
                  <div className="icon float-left">
                    <i className="mdi mdi-map-marker-outline mdi-36px mr-3"></i>
                  </div>
                  <div className="mt-3 overflow-hidden">
                    <h4 className="font-weight-bold mb-0">Location</h4>
                    <p className="h5">{contacts.address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <footer className="footer footer-bar">
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="text-sm-left">
              <p className="mb-0"> © 2020 {title}</p>
            </div>
          </div>

          <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0"></div>
        </div>
      </div>
    </footer>
  </React.Fragment>
);

const FooterWithQuery = props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            siteTitle
            contacts {
              address
              email
              phone
            }
          }
        }
      }
    `}
    render={data => (
      <Footer
        title={data.site.siteMetadata.siteTitle}
        contacts={data.site.siteMetadata.contacts}
        {...props}
      />
    )}
  />
);

export default FooterWithQuery;