import React from 'react';

const Shape = ({ color }) => (
  <div className="position-relative">
    <div className="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
          fill={color || "currentColor"}
        ></path>
      </svg>
    </div>
  </div>
)

const DiagonalShape = ({ color, flipped }) => (
  <div className="position-relative">
    <div
      className={`${flipped ? 'shape-flipped' : 'shape'} overflow-hidden text-light`}
    >
      <svg
        viewBox="0 0 2880 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill={color}></path>
      </svg>
    </div>
  </div>
);

// Vital Ocean
// background: linear-gradient(90deg, #1CB5E0 0%, #000851 100%);

const WaveShape = ({ color }) => (
  <div className="position-relative">
    <div className="wave-shape">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
        <path
          fill={color}
          fillOpacity="1"
          d="M0,160L40,165.3C80,171,160,181,240,186.7C320,192,400,192,480,170.7C560,149,640,107,720,112C800,117,880,171,960,202.7C1040,235,1120,245,1200,240C1280,235,1360,213,1400,202.7L1440,192L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
);

const WaveShapeOcean = ({ color }) => (
  <div className="position-relative">
    <div className="wave-shape flip-x">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 270">
        <path
          fill={color}
          fillOpacity="1"
          d="M0,192L60,202.7C120,213,240,235,360,224C480,213,600,171,720,181.3C840,192,960,256,1080,266.7C1200,277,1320,235,1380,213.3L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
);

export default Shape;
export { DiagonalShape, WaveShape, WaveShapeOcean };